import Vuex from 'vuex'
import Vue from 'vue'
import http from './http'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        projects: [],
        hacks: [],
        examples: [],
        codes: [],
        skills: [],
        certificates: [],
        links: [],
    },
    mutations: {
        setProjects(state, data) {
            state.projects = data.filter(v => v.type == 'P');
        },
        setHacks(state, data) {
            state.hacks = data.filter(v => v.type == 'H');
        },
        setExamples(state, data) {
            state.examples = data;
        },
        setCodes(state, data) {
            state.codes = data.filter(v => v.type == 'C');
        },
        setSkills(state, data) {
            state.skills = data.filter(v => v.type != 'C');
        },
        setCertificates(state, data) {
            state.certificates = data;
        },
        setLinks(state, data) {
            state.links = data;
        }
    },
    actions: {
        async setAchievements(context) {
            let data = (await http.getList('Achievement', {}, true)).data;
            context.commit('setProjects', data);
            context.commit('setHacks', data);
        },
        async setExamples(context) {
            let data = (await http.getList('Example', {}, true)).data;
            context.commit('setExamples', data);
        },
        async setCertificates(context) {
            let data = (await http.getList('Certificate', {}, true)).data;
            context.commit('setCertificates', data);
        },
        async setSkills(context) {
            let data = (await http.getList('Skill', {}, true)).data;
            context.commit('setSkills', data);
            context.commit('setCodes', data);
        },
        async setLinks(context) {
            let data = (await http.getList('Link', {}, true)).data;
            context.commit('setLinks', data);
        },
    }
})

export default store;
