import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

let opts = {
  mode: 'history',
  routes: [
    {
      path: "/",
      component: () => import('../pages/Index.vue'),
      name: "Home",
    },
    {
      path: "/certificates",
      component: () => import('../pages/Certificates.vue'),
      name: "Certificates",
    },
    {
      path: "/crypto",
      component: () => import('../pages/Crypto.vue'),
      name: "Crypto",
    },
  ],
  linkExactActiveClass: 'active',
};
const router = new VueRouter(opts);

export default router
